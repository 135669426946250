import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import Menu from '../components/Molecules/Menu';
import utils from '../utils/formatCMS';
import HeaderWithSlashContainer from '../components/Atoms/HeaderWithSlashContainer';
import logo from '../public/Images/logo.webp';
import strings from '../constants/Strings';
import useIsMobile from '../hooks/useIsMobile';

const NotFoundPage = (props) => {
  const [isMobile] = useIsMobile();
  const router = useRouter();
  return (
    <div className="full-width d-flex f-row relative" style={{ minHeight: '100vh' }}>
      {/* Menu */}
      <div className="absolute absolute-right-top-corner index-2">
        <Menu items={utils.formatCMSDatas('menu', props)} fontColor="grey-800-text" />
      </div>
      <HeaderWithSlashContainer imageUrl="/Images/404.webp">
        {isMobile ? null : (
          <button type="button" onClick={() => router.push('/')}>
            <Image alt="logo" src={logo} width={206} height={206} />
          </button>
        )}
        <div className={`${isMobile ? 'mv-2 pl-4' : 'mv-6 pl-5'}`}>
          <h1 className="grey-800-text medium-weight" style={{ fontSize: 80 }}>404</h1>
          <h1 className="grey-800-text medium-weight">{strings.pageNotFound}</h1>
          <p className="grey-600-text normal-weight mb-5">{strings.notFoundDescription}</p>
          <ButtonComponent onClick={() => router.push('/')}>
            <div className="mv-2 mh-4">
              <span className="uppercase">{strings.backHome}</span>
            </div>
          </ButtonComponent>
        </div>
      </HeaderWithSlashContainer>
    </div>
  );
};

export default NotFoundPage;
